<template>
<bo-card title="Hero Image">
  <b-row>
    <b-col md="4" 
      v-for="key in Object.keys(setting)"
      :key="key"
    >
      <bo-card-img 
        :title="setting[key].page"
        :src="parent.uploader(setting[key].file, '/assets/images/no-slide.jpg')"
      >
        <template #buttons>
          <b-button
            v-if="parent.moduleRole('updatehero')"

            variant="secondary"
            pill
            class="btn-icon"
            v-b-tooltip.hover="'Update'"
            @click="openHero(setting[key], key)"
          >
            <i class="fas fa-pencil-alt" />
          </b-button>
        </template>
      </bo-card-img>
    </b-col>
  </b-row>
  <b-modal 
		id="HeroForm" 
		:title="'Update '+input.page"
		size="lg"
    hide-footer
    body-class="p-0"
	>
    <validation-observer
      v-slot="{ handleSubmit }" ref="VForm"
    >
      
      <b-form @submit.prevent="handleSubmit(doSubmitHero())">
        <b-card-body>
          <b-row>
            <b-col md="6">
              <div class="file-uploader">
                <label>Hero Image Desktop: </label>
                <Uploader v-model="input.file" type="company-hero"/>
                <VValidate 
                  name="Hero Image Desktop" 
                  v-model="input.file" 
                  :rules="{required:1}"
                />
              </div>

              <div class="file-uploader">
                <label>Hero Image Mobile: </label>
                <Uploader v-model="input.file_mobile" type="company-hero-mobile" use-preview/>
                <VValidate 
                  name="Hero Image Mobile" 
                  v-model="input.file_mobile" 
                  :rules="{required:1}"
                />
              </div>
            </b-col>
            <b-col md="6">
              <b-card class="border mb-0">
                <template #header>
                  <h5 class="card-title">Content (ID)</h5>
                </template>
                <b-form-group label-for="heroTitleId">
                  <template #label>Title <strong class="text-danger">*</strong></template>
                  <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="heroTitleId" v-model="input.title_id" @input="autoFill('alt_mobile_id','alt_id','title_id')"/>
                  <VValidate 
                    name="Title ID" 
                    v-model="input.title_id" 
                    :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                  />
                </b-form-group>
                <b-form-group label-for="altId">
                  <template #label>Alt Image <strong class="text-danger">*</strong></template>                  
                  <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="altId" class="mb-2" v-model="input.alt_id"  />
                  <VValidate 
                    name="Alt ID" 
                    v-model="input.alt_id" 
                    :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                  />
                </b-form-group>
                <b-form-group label-for="altMobileId">
                  <template #label>Alt Image Mobile <strong class="text-danger">*</strong></template>                  
                  <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="altMobileId" class="mb-2" v-model="input.alt_mobile_id"  />
                  <VValidate 
                    name="Alt Mobile ID" 
                    v-model="input.alt_mobile_id" 
                    :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                  />
                </b-form-group>
              </b-card>
              <b-card class="border mb-0">
                <template #header>
                  <h5 class="card-title">Content (EN)</h5>
                </template>
                <b-form-group label-for="heroTitleEn">
                  <template #label>Title <strong class="text-danger">*</strong></template>
                  <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="heroTitleEn" v-model="input.title_en" @input="autoFill('alt_mobile_en','alt_en','title_en')" />
                  <VValidate 
                    name="Title EN" 
                    v-model="input.title_en" 
                    :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                  />
                </b-form-group>
                <b-form-group label-for="altEn">
                  <template #label>Alt Image <strong class="text-danger">*</strong></template>  
                  <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="altEn" class="mb-2" v-model="input.alt_en"  />
                  <VValidate 
                    name="Alt EN" 
                    v-model="input.alt_en" 
                    :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                  />
                </b-form-group>
                 <b-form-group label-for="altMobileEn">
                  <template #label>Alt Image Mobile <strong class="text-danger">*</strong></template>                  
                  <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="altMobileId" class="mb-2" v-model="input.alt_mobile_en"  />
                  <VValidate 
                    name="Alt Mobile EN" 
                    v-model="input.alt_mobile_en" 
                    :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
                  />
                </b-form-group>          
              </b-card>
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-footer>
          <div class="text-right">
            <b-button type="submit" variant="info" class="btn-rounded">Save Changes</b-button>
          </div>
        </b-card-footer>
      </b-form>
    </validation-observer>
  </b-modal>
</bo-card>
</template>

<script>
import Gen from '@/libs/Gen'
import BoCard from "@/components/backend/BoCard.vue"
import BoCardImg from '@/components/backend/BoCardImg.vue'

export default{
  components:{BoCard, BoCardImg},
  props:{ setting:Object },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  data(){
    return {
      heroEditorOpen:false,
      input:{}
    }
  },
  methods:{
    openHero(item, key){
      this.input = JSON.parse(JSON.stringify(item))
      this.input.key = key
      this.$bvModal.show('HeroForm')
    },
    autoFill(to = "", to2 = "", from = ""){
      this.input[to] = this.input[from]
      this.input[to2] = this.input[from]    
    },
    doSubmitHero(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) return
        
        this.parent.loadingOverlay = true 
        const data = JSON.parse(JSON.stringify(this.input))

        Gen.apiRest(
          "/do/"+this.parent.modulePage, 
          {data: Object.assign({}, data, {type:'update-hero'})}, 
          "POST"
        ).then(res=>{
            this.parent.loadingOverlay = false
            let resp = res.data

            let setObj = this.setting
            setObj[this.input.key] = this.input
            this.$emit('update:setting', setObj)
            this.$bvModal.hide('HeroForm')

            resp.statusType = 200
            this.parent.doSetAlertForm(resp)

            setTimeout(()=>{ this.message="" },15000)
        }).catch(err=>{
            this.parent.loadingOverlay = false
            
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response?.data?.message
                err.messageError = err.message
                err.title = err.response?.data?.title
            }

            this.parent.doSetAlertForm(err)
        })
      })
    }
  },
}
</script>