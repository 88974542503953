<template>
  <b-container fluid>
    <bo-page-title />
    <section id="home">
      <b-tabs>
        <b-tab title="Page Settings">
          <HeroSection :setting.sync="heroSetting" />
          <LapKeuangan :data.sync="lapKeuanganData"/>
          <PresentasiSection :data.sync="presentasiData"/>
          <IkhtisarSection :data.sync="ikhtisarData"/>
          <LapTahunan :data.sync="lapTahunanData"/>
          <LapLainnya :data.sync="lapLainnyaData"/>
          <DisclosureSection :data.sync="lapDisclosure"/>
        </b-tab>
        <b-tab title="SEO Settings">
          <SeoSection :setting.sync="seoPageSetting" />
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>

<script>
import Gen from '@/libs/Gen'
import GlobalVue from '@/libs/Global.vue'

import HeroSection from './HeroSection.vue'
import SeoSection from './SeoSection.vue'
import LapKeuangan from './LapKeuangan.vue'
import PresentasiSection from './PresentasiSection.vue'
import IkhtisarSection from './IkhtisarSection.vue'
import LapTahunan from './LapTahunan.vue'
import LapLainnya from './LapLainnya.vue'
import DisclosureSection from './DisclosureSection.vue'
const _ = global._

export default {
  extends: GlobalVue,
  components: {
    HeroSection,
    SeoSection,
    LapKeuangan,
    PresentasiSection,
    IkhtisarSection,
    LapTahunan,
    LapLainnya,
    DisclosureSection,
  },
  data() {
    return {
      heroSetting:{},
      seoPageSetting:{},
      lapKeuanganData:{data:false},
      ikhtisarData:{data:false},
      presentasiData:{data:false},
      lapTahunanData:{data:false},
      lapLainnyaData:{data:false},
      lapDisclosure:{data:false},
    }
  },
  mounted(){
    this.getAllData()
  },
  computed: {
  },
  methods: {
    getAllData(){
      this.loadingOverlay=true
      this.restAPI('page-setting')
      this.restAPI('lap-keuangan-data')
      this.restAPI('ikhtisar-data')
      this.restAPI('presentasi-data')
      this.restAPI('lap-disclosure-data')
      this.restAPI('lap-tahunan-data')
      this.restAPI('lap-lainnya-data')
    },
    doFilter(slug, params={}){
      this.restAPI(slug, params)
    },
    doReset(slug){
      this.restAPI(slug)
      this.filter={}    
    },
    restAPI(slug, params=false, callback=false){
      Gen.apiRest(
        "/get/"+this.modulePage+"/"+slug,
        {
          params:params||{}
        }
      ).then(res=>{
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        this.loadingOverlay=false
        if(callback) callback()
      })
    },
  },
  watch:{
    $route(){
      this.getAllData()
    }
  }
}
</script>
