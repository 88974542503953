<template>
<b-card>
  <template #header>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h5 class="card-title">Financial Overview</h5>
      </div>
      <b-button v-if="parent.moduleRole('add_'+obj_)" @click="openForm()" class="btn-rounded" variant="info">
        Add New Overview
        <i class="fas fa-plus-circle ml-2"></i>
      </b-button>
    </div>
  </template>

  <b-card-body>
    <b-row>
      <b-col md>
      </b-col>
      <b-col md="auto">
        <div class="d-flex">
            <b-form @submit.prevent="parent.doFilter(obj+'-data',parent.filter)" class="d-flex">
            <b-input-group>
            <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" v-model="parent.filter.searchFinanceOverview" v-on:keyup.13="$emit('searchFinanceOverview')" placeholder="Type keyword then enter..."></b-form-input>
            <b-input-group-append>
              <b-button @click="parent.doFilter(obj+'-data',parent.filter)" variant="success"><i class="fas fa-search"></i></b-button>
            </b-input-group-append>
            </b-input-group>
            <b-button
              class="ml-1 d-inline-flex align-items-center"
              variant="outline-success"
              @click="parent.doReset(obj+'-data')"
            >Reset</b-button>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </b-card-body>
  <b-table
    :fields="fields"
    :items="dataList||[]"
    :per-page="perPage"
    :busy="!dataList||processPage"
    responsive
    show-empty
    striped bordered
    class="text-center mt-4 b-table"
    thead-tr-class="table-info"
  > 
    <template #empty>
      <div class="text-center">
        <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
        <h4 align="center">There is no data</h4>
      </div>
    </template>
    <template #table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </template>
    <template #cell(number)="v">
      {{(data.per_page*(data.current_page-1))+v.index+1}}
    </template>
    <template #cell(is_active)="data">
      <b-badge
        v-if="data.value=='Y'"
        variant="success"
      >Active</b-badge>
      <b-badge
        v-else
        variant="danger"
      >Inactive</b-badge>
    </template>

    <template #cell(year)="v">
      {{ v.item.period | moment('YYYY') }}
    </template>

    <template #cell(period)="v">
      {{ v.value | moment('DD MMM YYYY') }}
    </template>

    <template #cell(filename)="v">
      <div class="wrap_pdf">
        <a :href="parent.uploader(v.item.file)" target="_blank"><img src="/assets/images/pdf.png" /> {{ v.value }}</a>
      </div>
    </template>

    <template #cell(actions)="data">
      <b-button v-if="parent.moduleRole('update_'+obj_)" class="btn-icon" variant="outline-warning" @click="openForm(data.item, data.index)">
        <i class="fas fa-pencil-alt"></i>
      </b-button>
      <b-button v-if="parent.moduleRole('update_'+obj_)" class="btn-icon" variant="outline-info" @click="doChangeStatus(data.index, data.item)">
        <i class="fas fa-cog"></i>
      </b-button>
      <b-button v-if="parent.moduleRole('delete_'+obj_)" class="btn-icon" variant="outline-danger" @click="doDelete(data.item, data.index)">
        <i class="fas fa-trash-alt"></i>
      </b-button>
    </template>
  </b-table>

  <template #footer>
    <b-pagination
      v-if="(data.total||0) > perPage"

      class="mb-0 justify-content-end"
      v-model="currentPage"
      :per-page="data.per_page"
      :total-rows="data.total||0"
    />
  </template>

  <b-modal
    no-close-on-backdrop
    :id="'modal'+obj"
    :title="input.index !== false ? 'Update ' + 'Financial Overview' : 'Add New '+'Financial Overview'"
    @ok="doSubmitContent" :busy="parent.loadingOverlay"
    cancel-variant="secondary btn-rounded"
    ok-variant="primary btn-rounded"
    ok-title="Save Changes"
  >
    <b-overlay
      :show="parent.loadingOverlay"
      rounded="sm"
    >
      <validation-observer
        v-slot="{ handleSubmit }" :ref="'VForm'+obj"
      >
        <b-form @submit.prevent="handleSubmit(doSubmitContent(false))">
          <div class="file-uploader mb-3">
            <a v-if="input.file" :href="parent.uploader(input.file)" target="_blank" class="d-flex justify-content-center mb-2">
              <div class="file-uploader-media"><div class="text-center"><strong>File Uploaded</strong></div></div>
            </a>
            <Uploader v-model="input.file" label="Report Document" type="company-ikhtisar" :noPreview="true"/>
            <VValidate 
              name="Report Document" 
              v-model="input.file" 
              :rules="{required:1}"
            />
          </div>
          <b-form-group label-for="reportTitleId">
            <template #label>Title (ID) <strong class="text-danger">*</strong></template>
            <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="reportTitleId" v-model="input.title_id" />
            <VValidate 
              name="Title ID" 
              v-model="input.title_id" 
              :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
            />
          </b-form-group>
          <b-form-group label-for="reportTitleEn">
            <template #label>Title (EN) <strong class="text-danger">*</strong></template>
            <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="reportTitleEn" v-model="input.title_en" />
            <VValidate 
              name="Title EN" 
              v-model="input.title_en" 
              :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
            />
          </b-form-group>
          <b-form-group label-for="reportPubDate">
            <template #label>Publish Date <strong class="text-danger">*</strong></template>
            <div class="input-group mb-3">
              <datepicker input-class="form-control transparent" v-model="input.period"></datepicker>
              <div class="input-group-append calendar-group">
                <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
              </div>
            </div>
            <VValidate 
              name="Publish Date" 
              v-model="input.period" 
              :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
            />
          </b-form-group>
          <!--for submit -->
          <b-button type="submit" class="d-none"></b-button>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</b-card>
</template>

<script>
import Gen from '@/libs/Gen'
import Datepicker from 'vuejs-datepicker'

export default{
  components: {
    Datepicker
  },
  props:{ data:[Array, Object, Boolean] },
  data(){
    return {
      obj:'ikhtisar',
      perPage: 5,
      currentPage: 1,
      processPage:false,
      idKey: 'id',
      statusKey: 'is_active',
      fields:[
        {
          key:'number', label:'#'
        },
        {
          key:'year', label:'Year'
        },
        {
          key:'period', label:'Publish Date'
        },
        {
          key:'title_id', label:'Financial Report Name'
        },
        {
          key:'filename', label:'File Download'
        },
        {
          key:'is_active', label:'Status'
        },
        'actions'
      ],
      input:{},
    }
  },
  computed:{
    dataList(){return this.data.data },
    obj_(){return this.obj.replace(/-/g,'_')},
    parent(){
      return this.$parent.$parent.$parent
    },
  },
  methods:{
    openForm(row={}, index=false){
      this.input = JSON.parse(JSON.stringify(row))
      this.input.index = index 
      this.$bvModal.show('modal'+this.obj)
    },
    doSubmitContent(bvModalEvt){
      if(bvModalEvt){
        bvModalEvt.preventDefault()
      }
      this.$refs['VForm'+this.obj].validate().then(success => {
        if (!success) return

        const input = this.input
        this.parent.loadingOverlay = true
        const data = JSON.parse(JSON.stringify(input))

        Gen.apiRest(
          "/do/"+this.parent.modulePage, 
          {data: Object.assign({}, data, {
            type:input.index===false?'add-'+this.obj:'update-'+this.obj, 
            id:input.id
          })}, 
          "POST"
        ).then(res=>{
            this.parent.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            this.parent.doSetAlertForm(resp)

            let faqData = this.dataList
            if(input.index===false){
              faqData.push(resp.data.row)
              this.data.total = this.data.total+1
              this.parent.submitCRUDToAlgolia('ikhtisar_id_',resp.data.rowAlgoliaID, true)
              this.parent.submitCRUDToAlgolia('ikhtisar_en_',resp.data.rowAlgoliaEN, true)
            }else{
              this.$set(faqData, input.index, resp.data.row)
              this.parent.submitCRUDToAlgolia('ikhtisar_id_',resp.data.rowAlgoliaID, false, input.index)
              this.parent.submitCRUDToAlgolia('ikhtisar_en_',resp.data.rowAlgoliaEN, false, input.index)
            }
            this.$bvModal.hide('modal'+this.obj)

        }).catch(err=>{
            this.parent.loadingOverlay = false
            
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response?.data?.message
                err.messageError = err.message
                err.title = err.response?.data?.title
            }

            this.parent.doSetAlertForm(err)
        })
      })
    },
    doChangeStatus(k,v){
      let id = v[this.idKey]
      let changeStatus = v[this.statusKey] == "Y" ? "N" : "Y"
      this.$swal({
        title: `Change status to ${changeStatus=='N' ? 'Inactive' : 'Active'}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Yes, change to ${changeStatus=='N'? 'Inactive' : 'Active'}!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.parent.loadingOverlay=true
          Gen.apiRest(
            "/do/"+this.parent.modulePage,
            {data:{type:'update-status-'+this.obj,id:id,status:changeStatus}}, 
            "POST"
          )
            .then(res=>{
              this.parent.loadingOverlay = false 
              v[this.statusKey] = changeStatus

              let resp = res.data                            
              this.parent.submitCRUDToAlgolia('ikhtisar_id_',resp.data.rowAlgoliaID, false, v.id)
              this.parent.submitCRUDToAlgolia('ikhtisar_en_',resp.data.rowAlgoliaEN, false, v.id)

              this.$swal({
                title: `Status changed to ${changeStatus=='Y'? 'Active' : 'Inactive'}.`,
                icon: 'success',
              })

              this.parent.restAPI(this.obj+'-data')
            })
            .catch(err=>{ 
              this.parent.loadingOverlay = false 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.message = err.response.data.message
                  err.title = err.response.data.title
                  err.messageError = err.message
              }
              this.parent.doSetAlertForm(err)
            })
        }
      })
    },
    doDelete(v){
      this.$swal({
        title: 'Delete this Overview ?',        
        text: "You won't be able to restore this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.parent.loadingOverlay = true

          Gen.apiRest(
            "/do/"+this.parent.modulePage, 
            {data: Object.assign({}, {
              type:'delete-'+this.obj, 
              id:v.id
            })}, 
            "POST"
          )
            .then(()=>{
              this.parent.loadingOverlay = false 
              this.parent.restAPI(this.obj+'-data')
              this.parent.deleteFromAlgoliaList('ikhtisar_en_',v.id)
              this.parent.deleteFromAlgoliaList('ikhtisar_id_',v.id)
              this.$swal({
                title: 'Overview Deleted Successfully!',
                icon: 'success',
              })
            })
        }
      })
    },
  },
  watch:{
    currentPage(v){
      this.processPage=true
      this.parent.restAPI(this.obj+'-data', {page:v}, ()=>{
        this.processPage=false
      })
    }
  }
}
</script>